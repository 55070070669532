import { ComponentRef, FlowEditorSDK, FlowPlatformOptions } from '@wix/yoshi-flow-editor';
import { REVIEWS_APP_ID } from '~/app-ids';

export const addTPAInternalPage = async (
  editorSDK: FlowEditorSDK,
  { pageId, title }: { pageId: string; title: string },
) => {
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', REVIEWS_APP_ID);
  return editorSDK.pages.add('', {
    title,
    shouldAddMenuItem: false,
    shouldNavigateToPage: false,
    definition: {
      data: {
        tpaApplicationId: appData.applicationId,
        appDefinitionId: REVIEWS_APP_ID,
        tpaPageId: pageId,
        managingAppDefId: REVIEWS_APP_ID,
        descriptionSEO: '',
        indexable: false,
        isLandingPage: false,
        isMobileLandingPage: false,
        isPopup: false,
        metaData: {
          isHidden: false,
          isPreset: false,
          pageId: 'masterPage',
        },
        metaKeywordsSEO: '',
        pageSecurity: {
          dialogLanguage: '',
          passwordDigest: '',
          requireLogin: false,
        },
        pageTitleSEO: '',
        pageUriSEO: pageId,
        translationData: {
          uriSEOTranslated: false,
        },
        type: 'Page',
        underConstruction: false,
      },
    },
  });
};

export const isTpaPageInstalled = async (
  editorSDK: FlowEditorSDK,
  tpaPageId: string,
): Promise<boolean> => {
  const appPages = (await editorSDK.document.pages.getApplicationPages('')) ?? [];
  return appPages.some((page) => page.tpaPageId === tpaPageId);
};

export const isWidgetInstalled = async (
  editorSDK: FlowEditorSDK,
  widgetId: string,
): Promise<boolean> => {
  const widgets = (await editorSDK.document.tpa.app.getAllComps('', REVIEWS_APP_ID)) ?? [];
  return widgets.some((w) => w.widgetId === widgetId);
};

export const getFirstSectionRef = async (
  editorSDK: FlowEditorSDK,
  pageRef: ComponentRef,
  options: FlowPlatformOptions,
): Promise<ComponentRef> => {
  const childrenRefs = await editorSDK.document.components.getChildren('', {
    componentRef: pageRef,
  });

  if (!childrenRefs?.length) {
    return pageRef;
  }

  if (isResponsiveEditor(options)) {
    return childrenRefs.length > 0 ? childrenRefs[1] : childrenRefs[0];
  } else {
    return childrenRefs[0];
  }
};

export const isResponsiveEditor = (options: FlowPlatformOptions) =>
  options.origin.type === 'RESPONSIVE';
