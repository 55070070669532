import { ComponentRef, EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { COLLECTION_WIDGET_ID, REVIEWS_APP_ID } from '~/app-ids';
import { HEADER_GAP } from './constants';

export const installCollectionWidgetsStudio = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  sectionRef: ComponentRef,
) => {
  const t = flowAPI?.translations.t.bind(flowAPI?.translations);
  // First we add grid to section
  const sectionLayout = await editorSDK.responsiveLayout.get('', { componentRef: sectionRef });
  await editorSDK.responsiveLayout.update('', {
    componentRef: sectionRef,
    responsiveLayout: {
      ...sectionLayout,
      containerLayouts: [
        {
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'maxContent',
            },
            {
              type: 'maxContent',
            },
            {
              type: 'maxContent',
            },
          ],
          columns: [
            {
              type: 'MinMaxSize',
              min: {
                type: 'px',
                value: 0,
              },
              max: {
                type: 'fr',
                value: 1,
              },
            },
          ],
        } as any,
      ],
    },
  });
  await Promise.all([
    addRichTextComponent({
      editorSDK,
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      pageRef: sectionRef,
      text: `<h2 class="font_2">${t?.('collection-page-header.line-1')}</h2>`,
    }),
    addRichTextComponent({
      editorSDK,
      gridArea: {
        rowStart: 2,
        rowEnd: 3,
        columnStart: 1,
        columnEnd: 2,
      },
      pageRef: sectionRef,
      text: `<p class="font_8">${t?.('collection-page-header.line-2')}</p>`,
      gap: HEADER_GAP,
    }),
  ]);
  const appData = await editorSDK.tpa.app.getDataByAppDefId('', REVIEWS_APP_ID);
  await editorSDK.document.components.addAndAdjustLayout('', {
    componentDefinition: {
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layouts: {
        type: 'SingleLayoutData',
        componentLayout: {
          type: 'ComponentLayout',
          width: {
            type: 'auto',
          },
          height: {
            type: 'auto',
          },
        },
        itemLayout: {
          id: undefined as any,
          type: 'GridItemLayout',
          gridArea: {
            rowStart: 3,
            rowEnd: 4,
            columnStart: 1,
            columnEnd: 2,
          },
          margins: {
            left: {
              type: 'percentage',
              value: 5,
            },
            right: {
              type: 'percentage',
              value: 5,
            },
            top: {
              type: 'spx',
              value: 0,
            },
            bottom: {
              type: 'spx',
              value: 0,
            },
          },
          justifySelf: 'stretch',
          alignSelf: 'stretch',
        },
      },
      data: {
        applicationId: `${appData.applicationId}`,
        type: 'TPAWidget',
        appDefinitionId: REVIEWS_APP_ID,
        widgetId: COLLECTION_WIDGET_ID,
      },
    },
    pageRef: sectionRef,
  });
};

type GridArea = {
  rowStart: number;
  rowEnd: number;
  columnStart: number;
  columnEnd: number;
};

const addRichTextComponent = async ({
  editorSDK,
  gridArea,
  gap = 0,
  text,
  pageRef,
}: {
  editorSDK: FlowEditorSDK;
  gridArea: GridArea;
  text: string;
  pageRef: ComponentRef;
  gap?: number;
}) => {
  return editorSDK.document.components.addAndAdjustLayout('', {
    componentDefinition: {
      type: 'Component',
      componentType: 'wysiwyg.viewer.components.WRichText',
      data: {
        type: 'StyledText',
        text,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      },
      layouts: {
        type: 'SingleLayoutData',
        componentLayout: {
          type: 'ComponentLayout',
          width: {
            type: 'percentage',
            value: 90,
          },
          height: {
            type: 'auto',
          },
          minWidth: {
            type: 'px',
            value: 0,
          },
          minHeight: {
            type: 'px',
            value: 0,
          },
          maxWidth: {
            type: 'px',
            value: 99999,
          },
          maxHeight: {
            type: 'px',
            value: 99999,
          },
        },
        itemLayout: {
          id: undefined as any,
          type: 'GridItemLayout',
          gridArea,
          margins: {
            left: {
              type: 'percentage',
              value: 5,
            },
            right: {
              type: 'percentage',
              value: 5,
            },
            top: {
              type: 'spx',
              value: 0,
            },
            bottom: {
              type: 'spx',
              value: gap,
            },
          },
          justifySelf: 'center',
          alignSelf: 'start',
        },
      },
    },
    pageRef,
  });
};
