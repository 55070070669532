export const MAIN_ARTICLE = '4e634f77-e58c-40f7-8489-069d079c7c1d';
export const DISPLAY_TAB = '5227436c-b9f2-49af-bedc-7636d6b89c7a';
export const DESIGN_TAB = '2fa1187e-04e9-4c56-aa35-2fe3b1dbb40f';
export const LAYOUT_TAB = 'e28c6963-207f-4f3d-adf5-e07ee7634db5';
export const SUPPORT_TAB = '5c0b6d3c-9af9-45b4-9bad-78074f500bcc';
export const RATING_SUMMARY_MAIN_ARTICLE = 'e24f2887-f09b-4d32-a2d9-99fe6c9e297a';
export const REVIEWS_PAGES = '13c7e704-68d7-4586-9b8b-21b031fa3c42';
export const COLLECTION_WIDGET_ARTICLE = '2261d10d-cd2b-4a51-ba05-5bb45d5c4750';
export const THANK_YOU_WIDGET_ARTICLE = '0fbcc5c2-b4a8-4442-9676-1a8b1ab29dfd';
export const COLLECTION_WIDGET_DESIGN_TAB = '289c5956-d5bb-4065-b312-73e822ee0024';
export const THANK_YOU_WIDGET_DESIGN_TAB = '5898da57-b8d4-4ced-8f16-b41bcefa748b';
