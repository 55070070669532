import { EditorScriptFlowAPI, FlowEditorSDK, FlowPlatformOptions } from '@wix/yoshi-flow-editor';
import { COLLECTION_PAGE_ID } from '~/app-ids';
import { addTPAInternalPage, getFirstSectionRef, isResponsiveEditor } from '~/editor/page-utils';
import { installCollectionWidgetsClassic } from '~/editor/install-collection-page/install-classic';
import { installCollectionWidgetsStudio } from '~/editor/install-collection-page/install-studio';

export const installCollectionPage = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  options: FlowPlatformOptions,
) => {
  try {
    const t = flowAPI?.translations.t.bind(flowAPI?.translations);
    const pageRef = await addTPAInternalPage(editorSDK, {
      title: t?.('collection-page.title'),
      pageId: COLLECTION_PAGE_ID,
    });
    if (isResponsiveEditor(options)) {
      const sectionRef = await getFirstSectionRef(editorSDK, pageRef, options);
      await installCollectionWidgetsStudio(flowAPI, editorSDK, sectionRef);
    } else {
      await installCollectionWidgetsClassic(flowAPI, editorSDK, pageRef);
    }
    return pageRef;
  } catch (e: any) {
    flowAPI.errorMonitor.captureException(e, {
      tags: { installFlow: 'COLLECTION_PAGE', editorType: options.origin.type },
    });
    throw e;
  }
};
